@font-face {
	font-family: "Roboto";
	src: url("../public/fonts/roboto/Roboto-Regular.eot");
	src: url("../public/fonts/roboto/Roboto-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/roboto/Roboto-Regular.woff") format("woff"),
		url("../public/fonts/roboto/Roboto-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url("../public/fonts/roboto/Roboto-Medium.eot");
	src: url("../public/fonts/roboto/Roboto-Medium.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/roboto/Roboto-Medium.woff") format("woff"),
		url("../public/fonts/roboto/Roboto-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url("../public/fonts/roboto/Roboto-Bold.eot");
	src: url("../public/fonts/roboto/Roboto-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/roboto/Roboto-Bold.woff") format("woff"),
		url("../public/fonts/roboto/Roboto-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

:root {
	--primary-font-color: #1f1f1f;
	--light-font-color: #818189;
	--primary-color: #fff;
	--secondary-color: #f6f6f6;
	--featured-blue: #283dc1;
	--header-height: 90px;
	--footer-height: 154px;
	/* --community-opened-color: #1ba524;
      --community-closed-color: #ffb820; */
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	height: 100vh;
	background: #fff;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	line-height: 1.4285em;
	color: rgba(0, 0, 0, 0.87);
	font-smoothing: antialiased;
	box-sizing: border-box;
}

#root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

main {
	flex: 1;
}

input,
textarea {
	outline: none;
}

button,
a {
	cursor: pointer;
}

a {
	text-decoration: none;
}

a:visited,
a:active {
	color: inherit;
}

.block_visible {
	visibility: visible;
	opacity: 1;
}

.block_hidden {
	visibility: hidden;
	opacity: 0;
}

.block_enabled {
	display: block;
}

.block_enabled-flex {
	display: flex;
}

.block_disabled {
	display: none;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Roboto, sans-serif;
}

h1 {
	font-size: 18px;
}

.container {
	padding: 16px;
	background-color: #f6f6f6;
}

/* Общие стили для каждого шага (ступени) */
.step {
	margin-bottom: 16px;
}

/* Первая ступень: Кнопка возврата и заголовок */
.step-1 {
	display: flex;
	align-items: center;
	gap: 16px;
}

.back-button {
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.step-1 h1 {
	font-size: 18px;
}

/* Вторая ступень: Инпут поиска, избранное и корзина */
.step-2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.search-input {
	width: 70%;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 8px;
}

.icons {
	display: flex;
	gap: 16px;
}

.icons button:nth-child(1) {
	background-image: url("../public/media/Ic_Favorite.svg");
}

.icons button:nth-child(2) {
	background-image: url("../public/media/Ic_cart.png");
}

.icon-button {
	border: none;
	min-width: 34px;
	min-height: 30px;
	border-radius: 12px;
	cursor: pointer;
	background-color: #eaeaea;
	background-repeat: no-repeat;
	background-position: center;
}

.step-2 > button {
	min-width: 31px;
	max-width: 31px;
}

/* Третья ступень: Выбранный город и кнопка выбора города */
.step-3 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.city {
	font-size: 16px;
	font-weight: bold;
}

.city-button {
	background-color: #f0f0f0;
	border: none;
	border-radius: 8px;
	font-size: 12px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* Четвертая ступень: Активные фильтры со скроллом вправо */
.step-4 {
	overflow-x: auto;
}

.filters {
	display: flex;
	gap: 8px;
}

.filter {
	background-color: #e6e6e6;
	padding: 8px;
	border-radius: 12px;
	font-size: 14px;
	white-space: nowrap;
}

/* Пятая ступень: Кнопка сортировки и уведомления */
.step-5 {
	display: flex;
	justify-content: space-between;
}

.sort-button,
.notify-button {
	background-color: #f0f0f0;
	border: none;
	padding: 8px 12px;
	border-radius: 8px;
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* Остальные стили, например, для товаров */
.product {
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	text-align: left;
}

.product img {
	max-width: 100%;
	height: auto;
	margin-bottom: 8px;
}

.product-info {
	font-size: 14px;
}

.old-price {
	text-decoration: line-through;
	color: red;
}

.rating {
	color: #3c3c3c;
	font-weight: 700;
	font-size: 12px;
}

/* Добавим стили для кастомного селекта */
.custom-select {
	position: relative;
	display: inline-block;
}

.sort-button {
	background-color: #f0f0f0;
	border: none;
	padding: 8px 12px;
	border-radius: 8px;
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* Стили для выпадающего меню сортировки */
.dropdown {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
	padding: 8px 0;
	border-radius: 8px;
	z-index: 1;
	min-width: 160px;
}

.custom-select:hover .dropdown {
	display: block;
}

.dropdown button {
	background-color: transparent;
	border: none;
	width: 100%;
	text-align: left;
	padding: 8px 16px;
	font-size: 14px;
	cursor: pointer;
}

.dropdown button:hover {
	background-color: #e0e0e0;
}

/* Остальные стили */

/* Добавим стили для кастомного селекта */
.custom-select {
	position: relative;
	display: inline-block;
}

.sort-button {
	background-color: #f0f0f0;
	border: none;
	padding: 8px 12px;
	border-radius: 8px;
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

/* Стили для выпадающего меню городов */
.dropdown {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
	padding: 8px 0;
	border-radius: 8px;
	z-index: 1;
	min-width: 160px;
}

.custom-select:hover .dropdown {
	display: block;
}

.dropdown button {
	background-color: transparent;
	border: none;
	width: 100%;
	text-align: left;
	padding: 8px 16px;
	font-size: 14px;
	cursor: pointer;
}

.dropdown button:hover {
	background-color: #e0e0e0;
}

/* Остальные стили */
/* Добавим стили для горизонтального скролла */
.product-scroll-container {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
}

.products {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	scroll-snap-align: start;
	height: 500px;
}

.product {
	flex: 0 0 45%; /* Каждая карточка занимает 45% ширины, чтобы вместить 2 в строке */
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.product img {
	width: 100%;
	height: auto;
	border-radius: 8px;
}

.product-info {
	padding: 8px 0;
}

.old-price {
	text-decoration: line-through;
	color: #999;
}

/* Стили для кастомного селекта */
.custom-select {
	position: relative;
	display: inline-block;
}

.sort-button {
	background-color: #f0f0f0;
	border: none;
	padding: 8px 12px;
	border-radius: 8px;
	font-size: 14px;
	cursor: pointer;
	margin-right: 8px;
}

.dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 8px;
	overflow: hidden;
	z-index: 1;
	width: 100%;
}

.dropdown button {
	padding: 8px 12px;
	width: 100%;
	text-align: left;
	background-color: #fff;
	border: none;
	cursor: pointer;
}

.dropdown button:hover {
	background-color: #f0f0f0;
}

.filters {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
}

.filter {
	background-color: #f0f0f0;
	padding: 8px 16px;
	margin-right: 8px;
	border-radius: 8px;
	cursor: pointer;
}

.step {
	margin-bottom: 16px;
}

/* Стили для кнопки уведомлений */
.notify-button {
	background-color: #f0f0f0;
	border: none;
	padding: 8px 12px;
	border-radius: 8px;
	font-size: 14px;
	cursor: pointer;
}
/* Добавим стили для горизонтального скролла */
.product-scroll-container {
	overflow-x: auto;
	scrollbar-width: none;
}

.products-row {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
}

.product-column {
	display: flex;
	flex-direction: column;
	margin-right: 16px;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.product {
	border-radius: 8px;
	background-color: transparent;

	box-shadow: none;
	/* Отступ между карточками внутри одной колонки */
}

.product img {
	width: 100%;
	height: auto;
	border-radius: 8px;
}

.product-info {
	padding: 8px 0;
	display: flex;
	gap: 6px;
	flex-direction: column;
}

.product-info > p:nth-child(2),
.product-info > p:nth-child(3) {
	font-weight: 700;
	font-size: 14px;
}

.product-info > h2 {
	font-weight: 400;
	font-size: 14px;
}

.old-price {
	text-decoration: line-through;
	color: #999;
	font-size: 11px;
}

.product-info p:nth-child(3) {
	font-size: 12px;
}

.rating {
	display: flex;
	flex-direction: row;
	align-items: start;
	gap: 3px;
	padding: 0;
	color: #818189;
}
.rating p {
	color: #000;
}

.rating::before {
	content: "";
	width: 12px;
	height: 12px;
	display: block;
	background-image: url("../public/media/star.svg");
}

/* Стили для кастомного селекта */
.custom-select {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.city-button {
	background-color: transparent !important;
	border: none;
	border-radius: 8px;
	font-size: 12px;
	cursor: pointer;
}

.dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 8px;
	overflow: hidden;
	z-index: 1;
	width: 100%;
}

.dropdown button {
	padding: 8px 12px;
	width: 100%;
	text-align: left;
	background-color: #fff;
	border: none;
	cursor: pointer;
}

.dropdown button:hover {
	background-color: #f0f0f0;
}

.filters {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
}

.filter {
	background-color: #f0f0f0;
	display: flex;
	align-items: center;
	padding: 9px 15px;
	margin-right: 0px;
	border-radius: 99px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
}

.step {
	margin-bottom: 12px;
}

.step-3 {
	margin-bottom: 8px !important;
}

/* Стили для кнопки уведомлений */
.notify-button {
	background-color: #f0f0f0;
	border: none;
	padding: 0;
	border-radius: 8px;
	font-size: 12px;
	text-align: right;
	cursor: pointer;
	justify-content: end;
}

.sort-button {
	padding: 0;
}

.cards {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.card {
	flex: 1;
	height: 5rem;
	display: flex;
	align-items: center;
	color: #fff;
	border-radius: 10px;
	padding: 16px;
	position: relative;
}

.card.spons {
	background-color: #ffb866;
}

.card.ispol {
	background-color: #ff6669;
}

.card span {
	width: 60%;
	line-height: 19px;
}

.card.ispol div {
	width: 70px;
	height: 70px;
	position: absolute;
	right: -15px;
	background-image: url("../public/media/spons.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.card.spons div {
	width: 70px;
	height: 70px;
	position: absolute;
	right: 0;
	background-image: url("../public/media/ispol.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: end;
	padding: 20px;
	z-index: 100;
}

.modal-content {
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	width: 100%;
	text-align: left;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-header h2 {
	margin: 0;
}

.clear-btn {
	background: none;
	border: none;
	color: #818189;
	cursor: pointer;
	font-size: 12px;
}

.clear-btn.clear {
	color: blue;
}

.modal-body {
	margin-top: 20px;
}

.radio-group {
	margin-bottom: 10px;
}

.radio-group label {
	margin-left: 10px;
}

.modal-footer {
	margin-top: 20px;
	text-align: center;
}

.apply-btn {
	background-color: #333;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}

.price-range {
	display: flex;
	flex-direction: row;
	gap: 24px;
}

.price-range > input {
	height: 51px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
	background-color: #fff;
	border: 0;
	outline: none;
	border-radius: 8px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	font-size: 16px;
	width: 50%;
}

.price-slider-container {
	position: relative;
	width: 100%;
	height: 40px; /* Можно изменить в зависимости от дизайна */
}

/* Базовые стили для обоих ползунков */
.price-slider {
	-webkit-appearance: none; /* Убираем стандартный стиль */
	appearance: none;
	width: 50%;
	height: 3px;
	background: #aaaaaa; /* Цвет линии */
	outline: none; /* Убираем фокус */
	pointer-events: none;
}

/* Ползунки (thumb) */
.price-slider::-webkit-slider-thumb {
	-webkit-appearance: none; /* Убираем стандартный стиль ползунка */
	appearance: none;
	width: 20px; /* Ширина ползунка */
	height: 20px; /* Высота ползунка */
	background: #4c4c4c; /* Цвет ползунка */
	cursor: pointer; /* Иконка курсора при наведении */
	border-radius: 50%; /* Делаем ползунки круглыми */
	pointer-events: all; /* Включаем возможность управления */
}

.price-slider::-moz-range-thumb {
	width: 8px;
	height: 8px;
	background: #4c4c4c;
	cursor: pointer;
	border-radius: 50%;
}

.price-slider::-ms-thumb {
	width: 8px;
	height: 8px;
	background: #232323;
	cursor: pointer;
	border-radius: 50%;
}

/* Стили для трека (линии), на которой движутся ползунки */

.price-slider::-ms-fill-lower {
	background: #242424; /* Цвет линии для IE */
}

.price-slider::-ms-fill-upper {
	background: #242424; /* Цвет линии для IE */
}

.radio-group {
	margin-top: 12px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.switch {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 20px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #eaeaea;
	transition: 0.4s;
	border-radius: 34px;
	width: 36px;
	height: 16px;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 0px;
	bottom: -2px;
	background-color: #7f7f7f;
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #aaaaaa;
}

input:checked + .slider:before {
	transform: translateX(16px);
	background-color: #505050;
}

/* Дополнительные стили */
.switch-group {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.switch-label {
	font-size: 16px;
	color: #232323;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-left: 0 !important;
}

.modal-fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content-filter {
	position: absolute;
	background-color: white;
	padding: 20px;
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
}

.apply-btn {
	cursor: pointer;
	background-color: #4c4c4c;
	color: white;
	border: none;
	padding: 10px 15px;
	border-radius: 44px;
	margin-right: 0;
}

.radio-group input[type="radio"]:checked {
	border: 2px solid #232323;
	padding: 4px;
	border-radius: 4px;
}

.dropdown-container {
	position: relative;
	display: inline-block;
	width: 100%;
	cursor: pointer;
}

.dropdown-trigger {
	height: 51px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 1px 6px 11px rgba(0, 0, 0, 0.1);
	font-size: 16px;
}

.dropdown-label {
	font-weight: 500;
	color: #333;
}

.dropdown-value {
	color: #888;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-top: 5px;
	z-index: 10;
}

.dropdown-menu div {
	padding: 10px 15px;
	cursor: pointer;
	transition: background-color 0.2s;
}

.dropdown-menu div:hover {
	background-color: #f1f1f1;
}

.modal-footer {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-scroll-container.horizontal {
	display: flex;
	overflow-x: auto; /* Горизонтальный скролл */
	padding: 10px 0;
}

.products-row.horizontal-row {
	display: flex;
	flex-wrap: nowrap; /* Элементы не будут переноситься на новую строку */
}

.city-button > span {
	width: 17px;
	height: 14px;
	background-image: url("../public/media/vibor.png");
	margin-right: 8px;
}

.city {
	display: flex;
	flex-direction: row;
	gap: 8px;
	font-size: 12px;
	font-weight: 400;
}
.city > span {
	display: block;
	width: 11px;
	height: 14px;
	background-image: url("../public/media/gorod.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.filter-button {
	display: flex;
	min-width: 44px;
	min-height: 36px;
	background-image: url("../public/media/Group.png");
	background-color: #eaeaea;
	border-radius: 12px;
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center;
}

.filter-button:active {
	background-color: #4c4c4c;
}

.step-2 {
	gap: 12px;
}

.step-2 > button:nth-child(2) {
	background-image: url("../public/media/Group 36481.png");
}

.sort-button span {
	display: block;
	width: 12px;
	height: 12px;
	margin-right: 8px;
	background-image: url("../public/media/sort.svg");
	background-repeat: no-repeat;
	background-size: 13px;
	background-position: center;
}

.sort-button {
	background-color: transparent !important;
	font-size: 12px;
}

.notify-button {
	background-color: transparent;
	width: 100%;
}

.notify-button span {
	display: block;
	width: 14px;
	height: 14px;
	margin-right: 8px;
	background-image: url("../public/media/Otobr.svg");
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: center;
}

.product > img {
	background-size: cover;
	width: 172px;
	height: 172px;
}

.product {
	width: 172px;
}

.back-button {
	display: block;
	width: 18px;
	height: 16px;
	margin-right: 16px;
	background-image: url("../public/media/arrow.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.back-button.rotate {
	display: block;
	margin-left: auto;
	width: 28px;
	height: 28px;
	margin-right: 8px;
	background-image: url("../public/media/arrow.png");
	background-color: #eaeaea;
	background-repeat: no-repeat;
	background-size: 16px;
	border-radius: 8px;
	background-position: center;
	rotate: 180deg;
}

.step.step-1 {
	margin-top: 10px;
	justify-content: space-between;
}

.sadas2 {
	position: relative;
}

.slick-list {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.slider-counter {
	position: relative;
	width: 44px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 26px;
	right: calc(-75% + 8px);
	background-color: #eaeaea;
	font-size: 10px;
	color: #818189;
	border-radius: 5px;
}

.slick-next,
.slick-prev {
	display: none !important;
}

.product img {
	margin-bottom: 0;
	height: 172px;
}

.search-input {
	background-image: url("../public/media/search.png"); /* Замените на путь к вашей иконке */
	background-repeat: no-repeat;
	background-position: 10px center; /* Позиция иконки внутри поля ввода */
	background-size: 20px 20px; /* Размер иконки */
	padding-left: 40px; /* Оставляем место для иконки слева */
	background-color: #eaeaea;
	border: 0;
	border-radius: 24px;
	outline: none;
}

.slick-dots {
	display: none !important;
}

.product-info {
	position: relative;
}

.product-izb {
	position: absolute;
	width: 15px;
	height: 18px;
	right: 0;
	background-image: url("../public/media/Ic_Favorite.svg");
	background-size: cover;
	background-position: center;
}

.filter.active {
	background-color: #4c4c4c;
	color: #ffffff;
}

.modal-header {
	position: relative;
}
.modal-header .back-button {
	position: absolute;
}

.modal-header h2 {
	margin-left: 33px;
	font-size: 18px;
}

.product-info {
	padding: 0;
	margin-top: 12px;
}

.filter.age::after {
	content: "";
	display: block;
	width: 17px;
	height: 14px;
	background-image: url("../public/media/age-dark.svg");
	margin-left: 8px;

	background-size: 16px;
	background-repeat: no-repeat;
}

.filter.age.active::after {
	content: "";
	display: block;
	width: 17px;
	height: 14px;
	background-image: url("../public/media/arrow-line.svg");
	margin-left: 8px;
	background-position: left;
	background-size: 16px;
	background-repeat: no-repeat;
}

.filter-button {
	background-image: url("../public/media/set.svg");
}

.filter-button.active {
	background-color: #4c4c4c;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAASCAYAAABfJS4tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACPSURBVHgB7ZPRDYAgDEQbJ3AERmBjRnAERmEE3KBCPEyDTdTyp77kPmjIhdIeUYGZXVEsyryz1BqNANMMBZgyzo6sCCMvah7Gkay01pV6fX1W6omvSVO5u0I9M2R+cUTb/VeonTwxbsNr2xDEMB2NAHO5DXHY9Fu8IrHuSOzdJCnG6p63dZ3Izp/Yk7ma2A0owZNsqYHi9gAAAABJRU5ErkJggg==);
}

.modal-content-filter {
	border-radius: 0;
}

.products-row {
	height: 100%;
	white-space: inherit;
}

.filters {
	padding-top: 4px;
	scrollbar-width: none;
}

.step-3 {
	margin-bottom: 12px;
}

.filter-button {
	position: relative;
}

.filter-count {
	width: 16px;
	height: 16px;
	background-color: #eaeaea;
	border-radius: 200px;
	font-size: 10px;
	font-weight: 500;
	color: #1f1f1f;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -4px;
	right: -4px;
	overflow: visible;
}

.slider-counter {
	padding: 3px 8px;
	line-height: 100%;
}

.no-products {
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	color: #818189;
}

.modal-content-filter .modal-footer {
	height: max-content;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
	bottom: 32px;
}

.modal-menu {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.modal-menu-header {
	display: flex;
	height: 118px;
	width: 100%;
	background-color: white;
	flex-direction: column;
	border-radius: 10px;
}

.modal-menu-header div {
	flex: 1;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	justify-content: space-between;
}

.modal-menu-header div:nth-child(2) {
	padding-top: 20px;
}

.modal-menu-header div:nth-child(3) {
	padding-bottom: 20px;
}

.modal-menu-header p {
	font-weight: 700;
}

.modal-menu-header > div {
	font-size: 15px;
}

.modal-menu button {
	width: 100%;
	margin-right: 0;
	background-color: white;
	color: #1f1f1f;
	padding: 13px 0;
	font-weight: 600;
}

.slick-slide > div,
.sadas2 {
	height: 172px;
}

.slick-slide,
.slick-track {
	height: 172px;
}

.dropdown-value {
	background-image: url("../public/media/Vector.svg");
	width: 10px;
	height: 10px;
	background-repeat: no-repeat;
	background-size: 10px;
}

.container {
	min-height: 100vh;
}

/* product */

.container.productPG .slick-track {
	height: 280px;
	width: 100%;
}

.container.productPG .slider-counter {
	right: calc(-100% + 52px);
	bottom: 26px;
}

.productPG > * {
	margin: 0;
	padding: 0;
	box-shadow: none !important;
}

.productPG * {
	box-shadow: none !important;
}

.productPG > .slick-slider {
	margin-top: 16px;
}

.productPG > .slick-slider img {
	height: 280px;
	width: 100%;
	object-fit: contain;
	border-radius: 16px;
	background-color: #fff;
}

.head__trener {
	margin-top: -2px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	position: relative;
}

.head__trener p {
	font-size: 20px;
	font-weight: 700;
	color: #1f1f1f;
}
.head__trener h1 {
	font-size: 16px;
	font-weight: 400;
	color: #1f1f1f;
}
.head__trener div {
	width: 18px;
	height: 22px;
	position: absolute;
	background-image: url("../public/media/Ic_Favorite.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	right: 0;
}

.btn__trener {
	margin: 25px 0;
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.btn__trener > a {
	background-color: #eaeaea;
	width: 100%;
	height: 51px;
	border-radius: 16px;
	font-size: 13px;
	font-weight: 600;
	color: #1f1f1f;
	text-decoration: none;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn__trener > a:hover {
	background-color: #d6d6d6;
}

.product__admin {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
}

.product__admin > div:nth-child(1) {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.product__admin > div:nth-child(1) > p {
	width: 10px;
	height: 10px;
	background-image: url("../public/media/Vector.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.btn__admin a {
	background-color: #fff;
	width: 100%;
	height: 51px;
	border-radius: 16px;
	font-size: 13px;
	font-weight: 600;
	color: #818189;
	text-decoration: none;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.btn__admin a p {
	width: 19px;
	height: 19px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.btn__admin a:nth-child(1) p {
	background-image: url("../public/media/Group 36552.svg");
}
.btn__admin a:nth-child(2) p {
	background-image: url("../public/media/Vector222.svg");
}

.btn__admin {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.srok__admin {
	display: flex;
	flex-direction: column;
	padding: 16px;
	background-color: white;
	border-radius: 16px;
	gap: 12px;
}

.srok__admin span {
	font-size: 13px;
	font-weight: 600;
	color: #818189;
}
.srok__admin p {
	font-size: 13px;
	font-weight: 600;
	color: #1ba524;
}

.srok__admin a {
	width: 100%;
	height: 40px;
	background-color: #4c4c4c;
	color: #fff;
	border-radius: 99px;
	font-size: 12px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.prod__sw {
	min-height: 56px;
	max-height: 80px;
	padding: 20px 16px;
	background-color: white;
	border-radius: 16px;
	margin: 0;
}

.prod__sw label {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.prod__sw span {
	font-size: 14px;
	color: #3c3c3b;
	flex: 1;
}

.product__admin > a {
	height: 40px;
	background-color: #4c4c4c;
	border-radius: 99px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: white;
	font-size: 12px;
	font-weight: 600;
}

.uslAndPrice {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 20px;
}

.uslAndPrice h1 {
	color: #1f1f1f;
	font-size: 18px;
	font-weight: 600;
}

.prices {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: start;
}
.prices div {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.prices > span {
	font-size: 14px;
	color: #1f1f1f;
	font-weight: 600;
}
.prices div span {
	color: #1f1f1f;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}
.prices div p {
	color: #818189;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}
.plaska {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 102px;
	background-color: #dcf2ff;
	border-radius: 10px;
	color: #1f1f1f;
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 20px;
}

.about__product {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-bottom: 20px;
}

.about__product h1 {
	color: #1f1f1f;
	font-size: 18px;
}

.about__product span {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: #818189;
	font-size: 14px;
}

.about__product span p {
	color: #3c3c3b;
}

.about__product > p {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}

.about__product > a {
	color: #249bbc;
	font-size: 14px;
	text-decoration: none;
	font-weight: 400;
}

.productPG > a {
	height: 40px;
	background-color: #4c4c4c;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	color: #fff;
	text-decoration: none;
	font-size: 12px;
	font-weight: 600;
	padding: 12px 0;
	border-radius: 99px;
}

.productPG > a:hover {
	background-color: #2a2a2a;
}

.productPG > a > p {
	width: 13px;
	height: 16px;
	background-image: url("../public/media/coloc.svg");
	background-size: cover;
	background-position: center;
}
.product__admin > a:hover,
.srok__admin a:hover {
	background-color: #2a2a2a;
}

.content,
.mxw {
	max-height: 0;
	overflow: hidden;
	transition: max-height 1s ease; /* Переход для плавного раскрытия */
}

.content.open {
	max-height: 1000px; /* Максимальная высота для раскрытого состояния */
}

/* Дополнительно можно подстроить max-height в зависимости от размера контента */

.productPG * {
	transition: all 0.5s ease;
}

.icons.prod > button:nth-child(1) {
	background-image: url("../public/media/header__product/Vector 14.svg");
}
.icons.prod > button:nth-child(2) {
	background-image: url("../public/media/Ic_Favorite.svg");
}
.icons.prod > button:nth-child(3) {
	background-image: url("../public/media/header__product/Ic_cart.svg");
}
.icons.prod > button:nth-child(4) {
	background-image: url("../public/media/Group 36481.png");
}

.head__trener h2 {
	color: #3c3c3c;
	font-size: 14px;
	font-weight: 400;
}

.subscribed {
	background-color: #eaeaea !important;
	color: #1f1f1f !important;
}

a.subscribed p {
	background-image: url("../public/media/header__product/Vector.svg");
}

a.subscribed:hover {
	background-color: #d6d6d6 !important;
}

.tolkVlad {
	font-weight: 400;
	font-size: 14px;
	color: #818189;
}

.step-1__left {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.step-2 input {
	width: 100%;
}

.card-predloj {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	height: 79px;
	border-radius: 16px;
	background-color: white;
	padding: 16px;
}

.ls,
.rs {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: space-between;
}

.ls span {
	color: #818189;
	font-size: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.ls span p {
	display: block;
	width: 2px;
	height: 2px;
	background-color: #818189;
	border-radius: 200px;
}

.card-predloj h2 {
	font-size: 16px;
	font-weight: 400;
	color: #1f1f1f;
}

.rs div {
	display: flex;
	flex-direction: row;
	gap: 3px;
}

.rs div p {
	width: 4px;
	height: 4px;
	background-color: #3c3c3c;
	display: block;
	border-radius: 200px;
}

.rs {
	align-items: end;
}

.predloj-cards {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 12px;
}

.product-scroll-container {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
}

/* Виды отображения */

.product-scroll-container.list-view,
.product-scroll-container.list-view .products-row,
.product-scroll-container.list-view .product {
	width: 100%;
}

.list-view .products-row {
	flex-direction: column;
	gap: 16px;
}

.product-column {
	margin-right: 16px;
	/* Сетка: фиксированная ширина */
	width: 100%;
}

.list-view .product-column {
	width: 100%;
	margin-right: 0;
}

.product-scroll-container.list-view .product {
	flex-direction: row;
	width: 100%;
	height: 140px;
	max-height: 140px;

	margin: 0;
}

.product-scroll-container.list-view .product-column {
	gap: 16px;
}

.product-scroll-container.list-view .product .slick-slider,
.product-scroll-container.list-view .product .slick-slider .slick-list {
	width: 140px;
	height: 140px;
}

.product-scroll-container.list-view .product .slick-slide > div,
.product-scroll-container.list-view .product .sadas2 {
	height: 140px;
}

.product-scroll-container.list-view .product img {
	height: 140px;
}

.product-scroll-container.list-view .slider-counter {
	right: -90px;
}

.product-scroll-container.list-view .product-info {
	margin-left: 16px;
	top: 10px;

	flex: 1;
	width: 100%;
}

.product-scroll-container.list-view .product-izb {
	right: 0;
}

.product-scroll-container.list-view {
	height: 100%;
	overflow-y: hidden;
}

.step-1 div:nth-child(1) {
	display: flex;
	flex-direction: row;
}

.step-1 div:nth-child(2) button:nth-child(3) {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAARCAYAAAAVKGZoAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABiSURBVHgB5U1BDYAwELseRpCABCwgARU8t3stqJiEIWESpmjllvDggQOaNE2btEUIaVbVLCKrs/XeN/dTIaWGcIDUSxVZzE7KC8OrazVL8QmitxrGJqAFkIVkde7yBfzj/Qb7Q2/NUEUC3wAAAABJRU5ErkJggg==);
}
.step-1 div:nth-child(2) button {
	min-width: 34px;
	max-width: 30px;
}

.step-2 span {
	color: #818189;
	font-size: 14px;
}

.modal .modal-stroke:nth-child(2),
.modal .modal-stroke:nth-child(3),
.modal .modal-stroke:nth-child(4) {
	justify-content: left;
	gap: 10px !important;
}

.modal .modal-stroke:nth-child(2) p {
	width: 18px;
	height: 18px;
	display: block;

	background-position: center;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("../public/media/Новая папка/Vector-1.svg");
	background-size: contain;
}

.modal .modal-stroke:nth-child(4) p {
	width: 18px;
	height: 18px;
	display: block;

	background-position: center;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("../public/media/Новая папка/Vector.svg");
	background-size: contain;
}

.modal .modal-stroke:nth-child(3) p {
	width: 18px;
	height: 18px;
	display: block;

	border: 2px solid #818189;
	border-radius: 2px;
}

.modal .modal-stroke:nth-child(2) label,
.modal .modal-stroke:nth-child(3) label,
.modal .modal-stroke:nth-child(4) label {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.clear-button.visible {
	color: #283dc1;
}

.modal h2 {
	font-size: 18px;
	font-weight: 600;
	margin-left: 24px;
}

.modal-menu-header.slsl h2 {
	margin-top: 16px;
}

.modal-content-filter .modal-body {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.modal .modal-stroke:nth-child(7),
.modal .modal-stroke:nth-child(6) {
	padding-top: 0;
	padding-bottom: 16px;
	padding-left: 16px;
}

.modal .modal-stroke:nth-child(7) {
	padding-bottom: 25px;
}

.modal .modal-stroke:nth-child(6) {
	padding-top: 20px;
}

.modal-menu-header.slsl div:nth-child(3) {
	padding-bottom: 8px;
}

.modal-menu-header.slsl div:nth-child(4) {
	padding-bottom: 20px;
}

.calendar {
	font-family: Arial, sans-serif;
}

.months {
	display: flex;
	gap: 8px;
	margin-bottom: 16px;
	margin-top: 16px;
	width: 100%;
	overflow-x: auto;
	scrollbar-width: none;
}

.month-button {
	padding: 10px 15px;
	border: none;
	background-color: lightgray;
	border-radius: 5px;
	cursor: pointer;
}

.month-button.active {
	background-color: #4c4c4c;
	color: white;
}

.days-grid.grid-active {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 5px;
}

.days-grid {
	display: flex;
	flex-direction: row;
	gap: 6px;
	overflow-x: scroll;
	width: 100%;
}

.day {
	padding: 10px 0;
	border-radius: 12px;
	text-align: center;
	min-width: 60px;
	height: 55px;
	height: 55px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	flex: 1;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}

.grid-active .day {
	background-color: transparent;
}
.priced {
	color: #818189 !important;
	font-size: 12px !important;
	height: 100%;
	z-index: 1000;
}
.selected-day .priced {
	color: #1ba524 !important;
	font-size: 12px !important;
}

.grid-active .day {
	height: 48px;
	min-width: 42px;
	width: 42px;
	border-radius: 4px;
}
.day div:nth-child(1) {
	display: flex;
	flex-direction: row;
}
.day div:nth-child(1),
.day p {
	color: #1f1f1f;
	font-weight: 700;
	font-size: 14px;
}

.day div:nth-child(2) {
	color: #1f1f1f;
	font-weight: 400;
	font-size: 14px;
}

.expand-button {
	cursor: pointer;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	display: flex;
	border: none;
	background-color: transparent;
	width: 14px;
	height: 8px;
	margin-top: 16px;
}

.arrow-down {
	width: 14px;
	height: 8px;
	background-image: url("../public/media/Vector.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.arrow-up {
	width: 14px;
	height: 8px;
	background-image: url("../public/media/Vector.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	rotate: 180deg;
}

.calendar > div:nth-child(1) {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.calendar > div:nth-child(1) p {
	display: block;
	width: 10px;
	height: 10px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("../public/media/Vector.svg");
}

.month-button {
	background-color: #eaeaea;
	border-radius: 99px;
}

.selected-day {
	background-color: #d8ddff !important;
	cursor: pointer;
	color: white;
}

.selected-day div:nth-child(1),
.selected-day p {
	color: #283dc1;
	font-weight: 700;
	font-size: 14px;
}

.selected-day div:nth-child(2) {
	color: #283dc1 !important;
	font-weight: 400;
	font-size: 14px;
}

.time-option {
	width: 100%;
	padding: 15px 0;
	text-align: center;
	border: 1px solid #d8d8d8;
	border-radius: 16px;
	margin: 16px 0;

	font-size: 15px;
	font-weight: 500;
	color: #3c3c3b;
}

.days-grid.grid-active .day p {
	display: none;
}

.days-grid .week-day {
	display: none;
}

.days-grid.grid-active .week-day {
	display: block;
}

.week-day {
	font-size: 14px;
	color: #1f1f1f;
	display: flex;
	justify-content: center;
	align-items: center;
	width: max-content;
	margin: 0 auto;
	margin-top: 12px;
}

.year-display h2 {
	font-size: 14px;
	font-weight: 600;
}

.days-grid {
	scrollbar-width: none;
}

.day.gray-out div {
	color: #c5c5c5;
}

.selected-time {
	border: 1px solid #1f1f1f;
}

.book-button {
	height: 40px;
	width: 100%;
	border-radius: 99px;
	border: none;
	background-color: #4c4c4c;
	color: #fff;
	font-size: 12px;
}

.book-button:hover {
	background-color: #2a2a2a;
}

.modal .switch-label span:nth-child(1) {
	font-size: 15px;
	width: 80%;
	flex: 0.8;
}

.modal .switch-label input {
	flex: 1;
}

.modal .switch-label {
	align-items: center;
}

.modal hr {
	border: 1px solid #e4e6e8;
}

.modal .switch-group {
	margin-bottom: 20px;
	padding-top: 20px;
	padding-bottom: 0;
}

.product-scroll-contasolo.list-view,
.product-scroll-contasolo.list-view .products-row,
.product-scroll-contasolo.list-view .product {
	width: 100%;
}

.solo-view .products-row {
	flex-direction: column;
	gap: 16px;
}

.product-column {
	margin-right: 16px;
	/* Сетка: фиксированная ширина */
	width: 100%;
}

.solo-view .product-column {
	width: 100%;
	margin-right: 0;
}

.product-scroll-container.solo-view .product {
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 100%;
	gap: 16px;
	margin: 0;
}

.product-scroll-container.solo-view .product-column {
	gap: 16px;
}

.product-scroll-container.solo-view .product .slick-slider,
.product-scroll-container.solo-view .product .slick-slider .slick-list {
	width: 100%;
	max-width: 328px;
	height: 328px;
}

.product-scroll-container.solo-view .product .slick-slide > div,
.product-scroll-container.solo-view .product .sadas2 {
	height: 328px;
}

.product-scroll-container.solo-view .product > div:nth-child(1) {
	height: 328px;
}

.product-scroll-container.solo-view .product img {
	height: 328px;
}

.product-scroll-container.solo-view .slider-counter {
	right: -275px;
}

.product-scroll-container.solo-view .product-info {
	margin-left: 0px;
	top: 0px;
	flex: 1;
	width: 100%;
}

.product-scroll-container.solo-view .product-izb {
	right: 0;
	width: 18px;
	height: 22px;
}

.product-scroll-container.solo-view {
	height: 100%;
	overflow-y: hidden;
	margin-top: 16px;
}

.day {
	position: relative;
}

.point {
	background-color: #283dc1; /* Цвет для текущего дня */
	width: 4px;
	height: 4px;
	border-radius: 1000px;
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
}

.next-month-heading {
	rotate: -90deg;
	font-size: 12px;
	color: #818189;
	height: max-content;
	width: 100%;
	transform: translatex(-100%);
}

.iskl {
	font-size: 18px;
	color: #818189;
	font-weight: 600;
	display: block;
	margin: 30px auto;
	width: max-content;
}

.head__trener a {
	color: #249bbc;
	text-decoration: none;
	font-size: 14px;
}
.head__trener .rating {
	color: #283dc1;
	font-size: 12px;
}

.head__trener .rating * {
	color: #1f1f1f;
	font-size: 12px;
}

.predloj-cards {
	margin-bottom: 20px;
}

.rs > div {
	cursor: pointer;
}

.fullscreen-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #0a0a0a;
	display: flex;
	flex-direction: column;
	z-index: 1000;
}

.header__fullscreen {
	display: flex;
	flex-direction: row;
	height: 64px;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #1c1c1c;
	padding: 0 19px;
}

.leftside__full,
.rightside__full {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.leftside__full {
	gap: 35px;
	color: #fff;
}

.leftside__full span {
	font-size: 18px;
	font-weight: 500;
}

.leftside__full button {
	background-image: url("../public/media/backbtn.svg");
}

.rightside__full {
	gap: 24px;
}

.sphere__icon {
	width: 32px;
	height: 32px;
	background-position: center;
	background-color: #1c1c1c;
	border-radius: 12px;
	background-repeat: no-repeat;
	background-size: 24px;
}

.dots__full {
	width: 24px;
	height: 24px;
	background-position: center;
	background-image: url("../public/media/Ic_more.svg");
	background-repeat: no-repeat;
	background-size: 24px;
}

.call {
	background-image: url("../public/media/Ic_Call.svg");
}
.mail {
	background-image: url("../public/media/Ic_Message.svg");
}

.fullscreen-overlay .slick-slider,
.fullscreen-overlay .slick-slider * {
	height: 100%;
}

.reviews-container {
	max-width: 500px;
	color: #1f1f1f;
}

.reviews-header {
	display: flex;
	justify-content: left;
	flex-direction: column;
	align-items: start;
	margin-bottom: 20px;
	width: 100%;
	gap: 16px;
}

.reviews-header .rating {
	font-size: 18px;
	color: #1f1f1f;
}

.reviews-header .rating:before {
	width: 18px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: cover;
	margin-right: 10px;
}

.reviews-header .reviews-count {
	font-size: 18px;
	color: #283dc1;
}

.reviews-header h2 {
	font-size: 18px;
}

.rating-summary {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 16px;
}

.add-review-btn {
	background: #d8ddff;
	color: #283dc1;
	padding: 13px 0;
	border-radius: 10px;
	border: none;
	cursor: pointer;
	width: 100%;
	font-size: 12px;
	font-weight: 600;
}

.review {
	border-radius: 8px;
	margin-bottom: 16px;
}

.review-header {
	display: flex;
	align-items: center;
	gap: 12px;
}

.profile-photo {
	width: 45px;
	height: 45px;
	border-radius: 10px;
	object-fit: cover;
}

.review-info {
	display: flex;
	flex-direction: column;
}

.review-author {
	font-weight: bold;
	font-size: 15px;
	color: #1f1f1f;
	line-height: 22.5px;
}

.review-date {
	color: #818189;
	font-size: 12px;
}

.review-rating {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 12px;
}

.review-text {
	color: #1f1f1f;
	margin-top: 14px;
	line-height: 21px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.deal-status {
	font-size: 13px;
	color: #818189;
}

.read-more {
	color: #249bbc;
	text-decoration: none;
	font-size: 14px;
}

.review-footer {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 12px;
	margin-top: 10px;
	color: #818189;
}

.view-all-btn {
	background: #fdf1df;
	color: #1f1f1f;
	padding: 13px 21px;
	border-radius: 10px;
	width: max-content;
	border: none;
	text-align: center;
	cursor: pointer;
	margin-top: 4px;
	margin-bottom: 20px;
	font-size: 12px;
	font-weight: 600;
}
.likes,
.dislikes {
	display: flex;
	flex-direction: row;
	gap: 6px;
}
.likes p,
.dislikes p {
	width: 14px;
	height: 14px;
	background-size: 14px;
	background-repeat: no-repeat;
}

.likes p {
	background-image: url("../public/media/like.svg");
}

.dislikes p {
	background-image: url("../public/media/dis.svg");
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: 20px;
	overflow-x: auto;
	scrollbar-width: none;
	border-bottom: 1px solid #e4e6e8;
}

.tabs button {
	color: #818189;
	font-size: 13px;
	background-color: transparent;
	border: none;
	white-space: nowrap;
	padding-bottom: 8px;
	font-weight: 600;
}

.tabs button.active {
	border-bottom: 2px solid #1f1f1f;
	color: #1f1f1f;
}

.application-card {
	background-color: white;
	padding: 16px;
	border-radius: 16px;
}

.application-card h3 {
	color: #1f1f1f;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: end;
}

.application-card h3 p {
	color: #3c3c3b;
	font-size: 12px;
}

.card-hr {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 20px;
}

.card-hr div {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.card-hr div h3 {
	color: #3c3c3b;
	font-size: 14px;
	font-weight: 400;
}

.card-hr img {
	width: 56px;
	height: 56px;
	border-radius: 10px;
}

.approved {
	padding: 6px 12px;
	color: #fff;
	background-color: #1ba524;
	border-radius: 99px;
	font-size: 12px;
}

.pending {
	padding: 6px 12px;
	color: #fff;
	background-color: #ffb820;
	border-radius: 99px;
	font-size: 12px;
}

.not.approved {
	padding: 6px 12px;
	color: #fff;
	background-color: #ea0130;
	border-radius: 99px;
	font-size: 12px;
}

.card-tt {
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.card-tt p:nth-child(1) {
	color: #818189;
}

.card-tt p:nth-child(2) {
	color: #3c3c3b;
	font-weight: 600;
}

.session {
	border-radius: 16px;
	background-color: #f6f6f6;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 16px;
}

.application-card textarea {
	background-color: #f6f6f6;
	padding: 20px 16px;
	height: 56px;
	border-radius: 10px;
	color: #818189;
	width: 100%;
	border: none;
}

textarea::placeholder {
	color: #818189;
	font-size: 14px;
	line-height: 16px;
}

.application-card > button {
	height: 51px;
	width: 100%;
	margin-top: 16px;
	border-radius: 16px;
	border: none;
	font-weight: 600;
}

.content.open {
	max-height: 3000px;
}

.mxw.open {
	max-height: 1500px;
}

.actions {
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-top: 16px;
	width: 100%;
}

.actions button {
	flex: 1;
	height: 40px;
	border-radius: 99px;
	border: none;
	font-weight: 600;
}

.actions button:nth-child(2) {
	background-color: #4c4c4c;
	color: white;
}

.notify-button img {
	margin-right: 8px;
}

strong {
	color: #000;
}

.avtor-block {
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin-bottom: 16px;
}

.ls-av {
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 10px;
}

.ls-av h1 {
	font-size: 20px;
	color: #1f1f1f;
}

.rs-av {
	width: 70px;
	height: 70px;
}

.rs-av img {
	width: 70px;
	height: 70px;
	border-radius: 10px;
}

.ls-av p {
	color: #818189;
	font-size: 14px;
}

.info__poshe {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 20px;
	color: #818189;
}

.ls-av .stars {
	display: flex;
	flex-direction: row;
	gap: 5px;
}

.ls-av .reviews-count {
	color: #283dc1;
	font-size: 12px;
}

.stars {
	display: flex;
	flex-direction: row;
	gap: 5px;
}
