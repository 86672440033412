.authorization-popup__loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 15;
	border-radius: 20px;
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
}

.authorization-popup__loader {
	width: 48px;
	height: 48px;
	background: #000;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.authorization-popup__wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 11;
	transition: 0.2s;
}

.authorization-popup {
	position: absolute;
	width: 538px;
	background: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 30px 50px;
	border-radius: 20px;
	z-index: 6;
}

.authorization-popup__popup-tint {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.1;
	transition: 0.2s;
	z-index: 5;
}

.authorization-popup__close-button {
	top: 30px;
	right: 30px;
	position: absolute;
	display: block;
	cursor: pointer;
	width: 16px;
	height: 16px;
	background-image: url(../../../public/images/buttons/cross.svg);
}

.authorization-popup__section,
.authorization-popup__section form {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.authorization-popup__section form {
	position: relative;
}

.authorization-popup__section > h1 {
	margin: 0;
	font-size: 30px;
	font-weight: 500;
	text-align: center;
	line-height: 40.6px;
}

.authorization-popup__section > h1 > span {
	position: absolute;
	cursor: pointer;
	top: 50px;
	left: 50px;
	width: 18px;
	height: 16px;
	background-image: url(../../../public/images/buttons/arrow-left.svg);
}

.authorization-popup__section form > button {
	margin-top: 10px;
}

.authorization-popup__section form > button,
.authorization-popup__successful-registration > button {
	font-size: 17px;
	font-weight: 600;
	border-radius: 20px;
	height: 70px;
}

.authorization-popup__section a {
	color: var(--featured-blue);
}

.authorization-popup__phone-hint {
	display: flex;
	align-items: center;
	font-size: 18px;
}

.authorization-popup__phone-hint img {
	width: 8px;
	height: 5px;
	margin-left: 5px;
}

.authorization-popup__section-name {
	display: flex;
	gap: 30px;
}

.authorization-popup__section-name input {
	padding-right: 0 !important;
}

.authorization-popup__section__alternative {
	text-align: center;
	color: #818189;
	margin: 0;
}

.authorization-popup__section__forgot-password {
	display: none;
}

.authorization-popup__agreement p {
	font-size: 14px;
	color: var(--primary-font-color);
	text-align: left;
	margin: 0;
}

.authorization-popup__agreement a {
	font-size: 14px;
	color: #249bbc;
}

.authorization-popup__agreement {
	position: relative;
	display: flex;
	align-items: center;
	gap: 20px;
	cursor: pointer;
	user-select: none;
	--checkbox-height: 20px;
	--checkbox-width: 20px;
}

.authorization-popup__agreement input {
	opacity: 0;
	height: var(--checkbox-height);
	width: var(--checkbox-width);
}

input[id^="regBirthDate"]::-webkit-inner-spin-button,
input[id^="regBirthDate"]::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

input[id^="regBirthDate"] {
	line-height: 23px;
	padding-right: 0 !important;
}

.authorization-popup__agreement__checkmark {
	position: absolute;
	height: var(--checkbox-height);
	width: var(--checkbox-width);
	border: 1px solid #dddddd;
	border-radius: 4px;
}

.authorization-popup__agreement
	input:checked
	+ .authorization-popup__agreement__checkmark {
	background-color: var(--featured-blue);
	border: 1px solid var(--primary-color);
}

.authorization-popup__agreement__checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.authorization-popup__agreement
	input:checked
	+ .authorization-popup__agreement__checkmark:after {
	display: block;
}

.authorization-popup__agreement__checkmark:after {
	left: 6px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid var(--primary-color);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.authorization-popup__section_agreement-message {
	position: absolute;
	transform: translate(-50%, 0);
	left: 50%;
	bottom: 85px;
	width: max-content;
	font-size: 12px;
	color: #ea0130;
	text-align: center;
	margin-top: -10px;
}

.authorization-popup__section__alternative,
.authorization-popup__section__alternative a {
	font-size: 18px;
	text-align: center;
}

.authorization-popup__successful-registration {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.authorization-popup__successful-registration-background {
	display: none;
}

.authorization-popup__successful-registration p,
.authorization-popup__successful-registration h1 {
	text-align: center;
	margin: 0;
}

.authorization-popup__successful-registration h1 {
	font-size: 29px;
	line-height: 40.6px;
	font-weight: 600;
}

.authorization-popup__successful-registration p {
	color: #818189;
	font-size: 18px;
}

.authorization-popup__successful-registration > button {
	margin-top: 10px;
}

@media (max-width: 900px) {
	.authorization-popup__section {
		gap: 20px;
	}

	.authorization-popup__section_agreement-message {
		bottom: 145px;
	}
}

@media (max-width: 570px) {
	.authorization-popup__wrapper {
		z-index: 9;
	}

	.authorization-popup {
		width: 100%;
		height: 100%;
		background: #f6f6f6;
		padding: 16px;
		top: 0;
		left: 0;
		transform: none;
		border-radius: 0;
		overflow: auto;
	}

	.authorization-popup__loader-wrapper {
		background: #f6f6f6;
	}

	.authorization-popup__section,
	.authorization-popup__section form {
		gap: 30px;
	}

	.authorization-popup__section button,
	.authorization-popup__section form > button {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 51px;
		gap: 10px;
		font-size: 12px;
		font-weight: 500;
		padding: 16px;
		border-radius: 16px;
	}

	.authorization-popup__section form > button {
		margin-top: 0;
	}

	.authorization-popup__section-name {
		flex-direction: column;
		gap: 30px;
	}

	.authorization-popup__section__forgot-password {
		display: inline;
	}

	.authorization-popup__section > button span {
		display: inline-block;
		width: 21px;
		height: 21px;
		background-image: url(../../../public/images/buttons/login_blue.svg);
	}

	.authorization-popup__section > button p {
		margin: 0;
		margin-top: 2px;
		font-size: 13px;
		font-weight: 500;
		color: var(--featured-blue);
	}

	.authorization-popup__agreement {
		gap: 15px;
	}

	.authorization-popup__agreement p,
	.authorization-popup__agreement a {
		font-size: 11px;
		line-height: 16.5px;
	}
	.authorization-popup__section_agreement-message {
		position: initial;
		transform: none;
		align-self: start;
    	margin: -15px 0;
	}

	.authorization-popup__section_agreement-message.reg {
		transform: none;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: 58px;
		margin: 0;
		align-self: center;
		position: absolute;
	}

	.authorization-popup > span {
		top: 28px;
		left: 16px;
		background-image: url(../../../public/images/buttons/arrow-left.svg);
	}

	.authorization-popup__section h1 {
		font-size: 18px;
		text-align: left;
		padding-left: 38px;
	}

	.authorization-popup__section h1 span {
		display: none;
	}

	.authorization-popup__section__alternative {
		display: none;
	}

	.authorization-popup__section__alternative-mobile {
		color: #818189;
		text-align: center;
	}

	.authorization-popup__section__alternative-mobile,
	.authorization-popup__section__alternative-mobile a {
		font-size: 12px;
		font-weight: 500;
		margin: 0;
	}

	.authorization-popup__section__alternative-mobile a {
		margin-left: 8px;
	}

	.authorization-popup__section_pick-auth-image {
		margin: 47px 0;
	}

	.authorization-popup__phone-hint {
		font-size: 15px;
	}

	.authorization-popup__successful-registration {
		height: fit-content;
		position: absolute;
		z-index: 16;
		padding: 16px;
		gap: 10px;
		background: #fff;
		border-radius: 10px;
		bottom: 50vh;
		left: 16px;
		right: 16px;
	}

	.authorization-popup__successful-registration-background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 15;
		display: block;
		width: 100%;
		height: 100vh;
		background: rgb(0, 0, 0, 0.25);
	}

	.authorization-popup__successful-registration p,
	.authorization-popup__successful-registration h1 {
		text-align: center;
	}

	.authorization-popup__successful-registration h1 {
		width: 80%;
		font-size: 18px;
		line-height: 24px;
		padding: 0;
		align-self: center;
	}

	.authorization-popup__successful-registration p {
		font-size: 13px;
	}

	.authorization-popup__successful-registration > button {
		margin-top: 15px;
	}
}
