.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 25px;
	background: var(--secondary-color);
}

.header > img {
	width: 168px;
	height: 40px;
}

.header__main-buttons {
	display: flex;
	gap: 20px;
}

.header__main-buttons button,
.header__add-announcement-button {
	font-size: 16px;
	font-weight: 500;
	padding: 18px 30px;
	border: none;
	border-radius: 1000px;
}

.header__main-buttons .main {
	color: #fff;
	background: #4c4c4c;
}

.header__main-buttons .services {
	color: #1f1f1f;
	background: #eaeaea;
}

.header__add-announcement-button {
	color: #ea0130;
	background: #ffeeee;
	margin-left: 54px;
	margin-right: 40px;
}

.header__nav-buttons {
	display: flex;
	gap: 30px;
}

.header__nav-buttons.mobile {
	display: none;
}

.header__nav-buttons > button a,
.header__nav-buttons > button {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
}

.header__nav-buttons > button {
	width: 56px;
	height: 65px;
	background: none;
	border: none;
	color: var(--light-font-color);
}

.header__nav-buttons.mobile > button {
	width: 34px;
	height: 30px;
}

@media screen and (max-width: 570px) {
	.header {
		padding: 16px;
	}

	.header > img {
		width: 125px;
		height: 30px;
	}

	.header__main-buttons {
		display: none;
	}

	.header__add-announcement-button {
		display: none;
	}

	.header__nav-buttons {
		display: none;
	}

	.header__nav-buttons.mobile {
		display: flex;
		gap: 16px;
	}
}
