.title {
    padding-top: 30px;
    padding-left: 30px;
}

.title h3 {
    margin-top: 10px;
}

.links {
    padding: 30px;
    padding-top: 15px;
}

.links a {
    color: var(--featured-blue);
    display: block;
    margin: 10px 0;
}