.footer {
	display: flex;
	flex-direction: column;
	gap: 22px;
	padding: 24px 0;
	height: var(--footer-height);
	background: #3c3c3c;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.footer__content {
	display: flex;
    justify-content: center;
    gap: 60px;
}

.footer__info-container p {
	color: #fff;
	font-size: 14px;
    font-weight: 500;
	margin-bottom: 16px;
}

.footer__links-container {
	display: flex;
    align-items: center;
	gap: 20px;
}

.footer__links-container a {
	display: flex;
    width: fit-content;
	height: fit-content;
}

.footer__links-container img {
	width: 40px;
	height: 40px;
}

.footer__info-container.mobile-app a:nth-child(1) img {
	width: 120px;
}

.footer__info-container.mobile-app a:nth-child(2) img {
	width: 130px;
}

.footer__info-container.about {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.footer__info-container.about p {
    margin: 0;
}

.footer__copyright {
	color: var(--light-font-color);
	text-align: center;
}

@media screen and (max-width: 570px) {
	.footer {
		align-items: center;
		justify-content: center;
	}

	.footer__info-container p {
		text-align: center;
	}

    .footer__info-container.social {
        display: none;
    }

    .footer__info-container.about {
        display: none;
    }

    .footer__info-container.support {
        display: none;
    }

	.footer__copyright {
		display: none;
	}
}
