.defaultInput__wrapper {
	position: relative;
	background: transparent;
}

.defaultInput__input {
	width: 100%;
	position: relative;
}

.defaultInput__input__showPassword {
	display: inline-block;
	position: absolute;
	cursor: pointer;
	top: 27px;
	right: 20px;
}

.defaultInput__input__showPassword img {
	width: 20px;
	height: 20px;
	background-color: #fff;
	border-radius: 50%;
}

.defaultInput__input__field {
	display: flex;
	align-items: baseline;
	width: 100%;
	border: 1px solid #d1d1d1;
	border-radius: 10px;
	margin-bottom: 5px;
	padding: 0 25px;
}

.defaultInput__input__field .defaultInput__input__field__hint {
	font-size: 18px;
	margin-right: 5px;
}

.defaultInput__input__field input {
	width: 100%;
	flex: 1;
	border: none;
	height: 70px;
	font-size: 18px;
	padding: 25px 0;
	padding-right: 21px;
}

 
.incorrect input,
.incorrect .defaultInput__input__field__hint {
	color: #ea0130;
}

.defaultInput__input input[type="password"] {
	font-family: Verdana !important;
}

.defaultInput__input input[type="password"]::placeholder {
	font-family: Roboto;
}

.defaultInput__input .defaultInput__input__label {
	position: absolute;
	font-size: 13px;
	background: #fff;
	margin: 0;
	color: #878787;
	padding: 0 10px;
	bottom: -8px;
	left: 25px;
}

.defaultInput__input .defaultInput__input__label span {
	position: absolute;
	display: inline-block;
	height: 4px;
	width: 4px;
	background: #ea0130;
	border-radius: 100%;
}

.defaultInput__input__message {
	position: absolute;
	width: max-content;
	left: 5px;
	bottom: -20px;
	margin: 0;
	height: 18px;
	margin-top: 10px;
	font-size: 12px;
	color: #ea0130;
}

.defaultInput__section__forgotPassword {
	display: none;
}

@media (max-width: 900px) {

	.defaultInput__input__showPassword {
		top: 26px;
	}
}

@media (max-width: 570px) {
    .defaultInput__section__forgotPassword {
		/* display: block;
		text-align: left; */
		font-size: 14px;
		/* margin-top: -10px; */
	}

    .defaultInput__input__field {
		/* padding: 0 16px; */
		/* border: none; */
		background: #F6F6F6;
		/* box-shadow: 1px 6px 11px 0px #00000008; */
	}

	.defaultInput__input__field input {
		/* padding: 20px 0; */
		height: 65px;
		font-size: 15px;
		background: #F6F6F6;
		/* padding-right: 28px; */
	}

	.defaultInput__input__field .defaultInput__input__field__hint {
		color: #878787;
		font-size: 15px;
	}

	.defaultInput__input__showPassword {
		top: 25px;
	}

	/* .defaultInput__input .defaultInput__input__label {
		position: initial;
		border: none;
		font-size: 16px;
		font-weight: 600;
		text-align: left;
		margin-bottom: 10px;
		background: none;
		color: var(--primary-font-color);
	} */

	.defaultInput__input .defaultInput__input__label {
		background: #F6F6F6;
	}

	/* .defaultInput__input .defaultInput__input__label span {
		width: 3px;
		height: 3px;
		margin-left: 2px;
	} */
}